import { useQuery } from "@tanstack/react-query";
import {
  getCheckoutStatus,
  checkout as checkoutApi,
  checkin as checkinApi
} from "api/conflict-management";
import {
  getCheckoutStatus as getCheckoutStatusV2,
  checkout as checkoutApiV2,
  checkin as checkinApiV2
} from "api/conflict-management-v2";
import { useContext, useState } from "react";
import { isMultitenancy } from "utils/multitenancy";
import { PermissionsContext } from "./permissions";

export const useConflictManagement = () => {
  // TODO: ASD Remove everything below this after all components are migrated to v2
  const [isCheckOutInLoading, setIsCheckOutInLoading] = useState(false);
  const perms = useContext(PermissionsContext).grid;

  const { refetch: refreshCheckoutStatus } = useQuery(
    ["checkout-status"],
    getCheckoutStatus,
    {
      enabled: perms.edit && !isMultitenancy
    }
  );

  const { refetch: checkout } = useQuery(["checkout"], checkoutApi, {
    enabled: false,
    retry: false
  });

  const { refetch: checkin } = useQuery(["checkin"], checkinApi, {
    enabled: false,
    retry: false
  });
  // TODO: ASD Remove everything above this after all components are migrated to v2

  const [isCheckOutInLoadingV2, setIsCheckOutInLoadingV2] = useState(false);
  const { refetch: refreshCheckoutStatusV2 } = useQuery(
    ["checkout-status"],
    getCheckoutStatusV2,
    {
      enabled: false
    }
  );

  const { refetch: checkoutV2 } = useQuery(["checkout"], checkoutApiV2, {
    enabled: false,
    retry: false
  });

  const { refetch: checkinV2 } = useQuery(["checkin"], checkinApiV2, {
    enabled: false,
    retry: false
  });

  return isMultitenancy
    ? {
        isCheckOutInLoading: isCheckOutInLoadingV2,
        setIsCheckOutInLoading: setIsCheckOutInLoadingV2,
        refreshCheckoutStatus: refreshCheckoutStatusV2,
        checkout: checkoutV2,
        checkin: checkinV2
      }
    : {
        isCheckOutInLoading,
        setIsCheckOutInLoading,
        refreshCheckoutStatus,
        checkout,
        checkin
      };
};
