import axios from "axios";
import { Record, Static, String } from "runtypes";

const AgendaConfigLinksValidator = Record({
  ceoStaff: String,
  functionalPa: String,
  operatingTeam: String
});

export type AgendaConfigLinks = Static<typeof AgendaConfigLinksValidator>;

const AgendaConfigValidator = Record({
  publishedDate: String
});

export type AgendaConfig = Static<typeof AgendaConfigValidator>;

export const getConfigLinks = async (): Promise<AgendaConfigLinks> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<AgendaConfigLinks>(
    `${REACT_APP_DOMAIN}/api/v1/agenda-config/links`
  );

  AgendaConfigLinksValidator.check(result.data);

  return result.data;
};

export const getAgendaConfig = async (): Promise<AgendaConfig> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<AgendaConfig>(
    `${REACT_APP_DOMAIN}/api/v1/agenda-config`
  );

  AgendaConfigValidator.check(result.data);

  return result.data;
};

export const putAgendaConfig = async (
  agendaConfig: AgendaConfig
): Promise<void> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  await axios.put(`${REACT_APP_DOMAIN}/api/v1/agenda-config`, agendaConfig);
};
