import React, { useContext, useEffect, useState } from "react";
import "./AgendaSelector.scss";
import { getAgendaId, isMultitenancy } from "utils/multitenancy";
import Dropdown, {
  MenuItemType,
  ItemType
} from "common-components/dropdown/Dropdown";
import { useNavigate } from "react-router-dom";
import pages from "pages/pages";
import { AgendaList, getAgendaList } from "api";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "global/query-config";
import { PermissionsContext } from "global/permissions";

const namespace = "rts-pa-agenda-selector";
const ADD_KEY = "add";

const initItem: MenuItemType = {
  key: "0",
  label: "Loading..."
};

const AgendaSelector = () => {
  const navigate = useNavigate();
  const agendaId = getAgendaId();
  const perms = useContext(PermissionsContext);

  const [agenda, setAgenda] = useState<MenuItemType>(initItem);
  const [agendas, setAgendas] = useState<ItemType[]>([]);

  const { data } = useQuery<AgendaList>(["agenda-list"], getAgendaList, {
    enabled: isMultitenancy
  });

  const onAgendaChange = (items: ItemType[], agendaId: string) => {
    let item = items.find(a => a?.key === agendaId);

    // on agenda delete, it refreshes list which triggers this reroute (other reroute in permissions)
    if (!item) {
      item = items[0];
      navigate(pages.grid.go(item?.key?.toString() || "1"));
    }

    if (item) {
      setAgenda(item as MenuItemType);
    } else {
      setAgenda(initItem);
    }

    // invalidate/refetch everything that's needed on agenda change; skip initially, and on agenda config update
    if (agenda.key !== "0" && agenda.key !== item?.key) {
      queryClient.removeQueries({
        predicate: query =>
          !["agenda", "agenda-list", "global-config"].includes(
            query.queryKey[0] as string
          )
      });
    }
  };

  useEffect(() => {
    if (!data?.agendas.length) {
      return;
    }

    let items = data.agendas.map<ItemType>(ali => ({
      key: ali.agendaId.toString(),
      label: `${ali.title} Prioritized Agenda`
    }));

    if (perms.grid.create) {
      items = items.concat([
        { type: "divider" },
        {
          key: ADD_KEY,
          label: "Create a new agenda +",
          style: { fontWeight: 700 }
        }
      ]);
    }

    setAgendas(items);

    onAgendaChange(items, getAgendaId());
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dropdown
      className={namespace}
      items={agendas}
      onMenuItemClick={info => {
        if (info.key === ADD_KEY) {
          navigate(pages.grid.addAgendaModal.go());
        } else {
          navigate(pages.grid.go(info.key));
          onAgendaChange(agendas, info.key);
        }
      }}
      selectable={true}
      defaultSelectedKey={agendaId}
    >
      {agenda.label}
    </Dropdown>
  );
};

export default AgendaSelector;
