import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import "./BiosimilarsModal.scss";
import PaModal from "common-components/pa-modal/PaModal";
import { BiosimilarsItemV2 } from "api/biosimilars-v2";
import { getAgendaItemCachedV2 } from "api/cache-v2";
import { PermissionsContext } from "global/permissions";
import { useNavigate } from "react-router-dom";
import { useModalHelper } from "global/use-modal-helper";
import pages from "pages/pages";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import SubmissionRequestV2 from "common-components/submission-request-v2/SubmissionRequestV2";
import SummaryInfo from "common-components/summary-info/SummaryInfo";
import OwnersContainerV2 from "common-components/owners-container-v2/OwnersContainerV2";
import BiosimilarsPipelineMetricsGroup from "./pipeline-metrics-group/BiosimilarsPipelineMetricsGroup";
import ModalExternalLinksV2 from "common-components/modal-external-links-v2/ModalExternalLinksV2";

const namespace = "rts-pa-biosimilars-modal";

const BiosimilarsModal = () => {
  const perms = useContext(PermissionsContext);
  const hasPermissions = perms.grid.biosimilarsModal;
  const navigate = useNavigate();

  const mhResult = useModalHelper(
    pages.grid.biosimilarsModal.path,
    hasPermissions,
    "biosimilars"
  );

  const { status, data, error } = useQuery<BiosimilarsItemV2>(
    ["agenda-item-cached", mhResult.itemId],
    () => getAgendaItemCachedV2("biosimilars", mhResult.itemId),
    {
      enabled: mhResult.enabled
    }
  );

  return (
    <PaModal
      title={mhResult.title}
      status={status}
      axiosErrors={error}
      isOpen={mhResult.enabled}
      onClose={() => {
        navigate(pages.grid.go());
      }}
      className={namespace}
    >
      <PermissionsWrapper permissions={perms.submissions}>
        <SubmissionRequestV2 agendaItem={mhResult.agendaItem} />
      </PermissionsWrapper>
      <SummaryInfo data={data?.summaryInfo} />
      <OwnersContainerV2
        owners={data?.owners}
        className="biosimilars-modal-v2"
      />
      <BiosimilarsPipelineMetricsGroup data={data?.pipeline} />
      <ModalExternalLinksV2
        links={data?.links}
        className="biosimilars-modal-v2"
        buttonType="primary"
      />
    </PaModal>
  );
};

export default BiosimilarsModal;
