import React from "react";
import "./TalentDinbModalTalentAmgenOverallEngagement.scss";
import {
  ComposedLineChartComponent,
  LineType
} from "@gitlab-rtsensing/component-library";
import { colors } from "utils";
import getAdvancedTooltipContent from "common-components/advanced-tooltip/getAdvancedTooltipContent";
import { TalentDinbTalentAmgenOverallEngagement } from "api/talent-dinb/talent-dinb-talent";
import { AccordionWithStatusV2 } from "common-components/accordion-with-status-v2/AccordionWithStatusV2";

type Props = {
  data: TalentDinbTalentAmgenOverallEngagement | undefined | null;
};

const TalentDinbModalTalentAmgenOverallEngagement = (props: Props) => {
  const namespace = "rts-pa-talent-dinb-modal-talent-amgen-overall-engagement";
  const title = "Amgen Overall Engagement";

  if (!props.data) {
    return <></>;
  }

  const chartData = props.data.chart.map(item => ({
    label: item.label || undefined,
    forecast: item.forecast !== null ? item.forecast : undefined,
    value: item.value !== null ? item.value : undefined
  }));

  return (
    <AccordionWithStatusV2
      status={props.data.status}
      title={title}
      tooltipContent={getAdvancedTooltipContent(props.data.tooltip)}
      className={namespace}
      accordionSyncKey="TalentModal"
      links={props.data.links}
    >
      <div className={`${namespace}-metrics`}>
        <ComposedLineChartComponent
          data={chartData}
          xAxisKey="label"
          dataKeys={[
            { label: "value", dot: true, color: colors.primaryBlue },
            {
              label: "forecast",
              dot: true,
              color: colors.yellowAccent,
              type: LineType.SOLID,
              strokeWidth: 2
            }
          ]}
          dataKeyFormatter={(key: string | number) =>
            key === "value" ? "AMGEN" : "GENERAL INDUSTRIES BENCHMARK"
          }
          chartHeight={200}
          useTooltip
          showLegend
          yAxisWidth={22}
          yAxisDomain={["dataMin - 2", "dataMax + 2"]}
        />
      </div>
    </AccordionWithStatusV2>
  );
};

export default TalentDinbModalTalentAmgenOverallEngagement;
