import React, { useContext, useMemo } from "react";
import PaModal from "common-components/pa-modal/PaModal";
import { useQuery } from "@tanstack/react-query";
import "./PipelineModal.scss";
import SummaryInfo from "common-components/summary-info/SummaryInfo";
import { PermissionsContext } from "global/permissions";
import { useNavigate } from "react-router-dom";
import pages from "pages/pages";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PipelineItemV2 } from "api/getPipelineItemV2";
import { getAgendaItemCachedV2 } from "api/cache-v2";
import { Link } from "api/common-v2";
import { useModalHelper } from "global/use-modal-helper";
import SubmissionRequestV2 from "common-components/submission-request-v2/SubmissionRequestV2";
import ModalExternalLinksV2 from "common-components/modal-external-links-v2/ModalExternalLinksV2";
import PipelineProjectV2 from "components/pipeline-project/PipelineProjectV2";

const namespace = "rts-pa-pipeline-modal";

const PipelineModal = () => {
  const perms = useContext(PermissionsContext);
  const hasPermissions = perms.grid.pipelineModal;
  const navigate = useNavigate();
  const mhResult = useModalHelper(
    pages.grid.pipelineModal.path,
    hasPermissions,
    "pipeline"
  );

  const { status, data, error } = useQuery<PipelineItemV2>(
    ["agenda-item-cached", mhResult.itemId],
    () => getAgendaItemCachedV2("pipeline", mhResult.itemId),
    {
      enabled: mhResult.enabled
    }
  );

  const links = useMemo(() => {
    const links: Link[] = [];

    if (data) {
      for (const link of data.links) {
        links.push(link);
      }
    }

    return links;
  }, [data]);

  return (
    <PaModal
      title={mhResult.title}
      status={status}
      axiosErrors={error}
      isOpen={mhResult.enabled}
      onClose={() => {
        navigate(pages.grid.go());
      }}
      className={namespace}
    >
      <PermissionsWrapper permissions={perms.submissions}>
        <SubmissionRequestV2 agendaItem={mhResult.agendaItem} />
      </PermissionsWrapper>
      <SummaryInfo data={data?.summaryInfo} />
      <PipelineProjectV2
        owners={data?.owners}
        nextApprovalLaunchE2l={data?.nextApprovalLaunchE2L}
        nextPriorityMilestone={data?.nextPriorityMilestone}
        studyEnrollments={data?.studyEnrollments}
      />
      <ModalExternalLinksV2
        links={links}
        className="pipeline-modal-v2"
        buttonType="primary"
      />
    </PaModal>
  );
};

export default PipelineModal;
