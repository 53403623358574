import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  DragDropContext,
  OnDragEndResponder,
  OnDragUpdateResponder
} from "react-beautiful-dnd";
import update from "immutability-helper"; //TODO JCG: replace immutability-helper with immer in repo
import produce from "immer";
import AgendaGridBox from "./agenda-grid-box/AgendaGridBox";
import "./AgendaGrid.scss";
import { getAgendaItemsV2, AgendaItemsGroupV2, putAgendaItemsV2 } from "api";
import { updateGroupOrder } from "./agendaGridUtils";
import AgendaGridAxis from "./agenda-grid-axis/AgendaGridAxis";
import AgendaGridAxisVertical from "./agenda-grid-axis-vertical/AgendaGridAxisVertical";
import { Loader } from "@gitlab-rtsensing/component-library";
import classNames from "classnames";
import {
  onDragEnd as onDragEndPlaceholder,
  onDragUpdate as onDragUpdatePlaceholder
} from "./agenda-grid-line-item-placeholder/useAgendaGridLineItemPlaceholder";
import { useNavigate } from "react-router-dom";
import pages from "pages/pages";
import { useGridModeHelper } from "global/use-grid-mode-helper";
import AgendaGridPageHeader from "pages/home/header/AgendaGridPageHeader";
import useLimitedView from "global/use-limited-view";
import { ActivityLogV2 } from "components/activity-log/ActivityLogV2";

const namespace = "rts-pa-agenda-grid";

type Props = {
  onExportClick: () => void;
};

export const AgendaGrid = (props: Props) => {
  const [boxGroups, setBoxGroups] = useState<AgendaItemsGroupV2[]>([]);
  const [snapshotBoxGroups, setSnapshotBoxGroups] = useState<
    AgendaItemsGroupV2[] | undefined
  >();

  const navigate = useNavigate();
  const gridMode = useGridModeHelper();
  const { isLimitedView } = useLimitedView(pages.grid);

  const { status, data, refetch } = useQuery(
    ["agenda-items", isLimitedView],
    () => getAgendaItemsV2(isLimitedView)
  );
  const { mutate: updateAgendaItems } = useMutation(putAgendaItemsV2, {
    onSuccess: () => refetch()
  });

  useEffect(() => {
    if (status !== "success" || !data) {
      return;
    }

    setBoxGroups(data.groups);

    //needed when loading "rearrange" mode directly
    if (gridMode.isRearrangeMode) {
      setSnapshotBoxGroups(data.groups);
    }
  }, [status, data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onDragEnd: OnDragEndResponder = result => {
    if (!result.destination) {
      return;
    }

    //get groups
    const srcGrpIndex = boxGroups.findIndex(
      g => result.source.droppableId === `${g.focus}${g.value}`
    );
    const destGrpIndex = boxGroups.findIndex(
      g => result.destination?.droppableId === `${g.focus}${g.value}`
    );
    if (srcGrpIndex === -1 || destGrpIndex === -1) {
      return;
    }

    const srcIndex = result.source.index - 1;
    const destIndex = result.destination.index - 1;

    //remove item from srcGroup
    let newGroups = update(boxGroups, {
      [srcGrpIndex]: {
        items: {
          $splice: [[srcIndex, 1]]
        }
      }
    });

    //add item to destGroup
    newGroups = update(newGroups, {
      [destGrpIndex]: {
        items: {
          $splice: [[destIndex, 0, boxGroups[srcGrpIndex].items[srcIndex]]]
        }
      }
    });

    //update groups order
    newGroups = updateGroupOrder(newGroups, srcGrpIndex);
    if (srcGrpIndex !== destGrpIndex) {
      newGroups = updateGroupOrder(newGroups, destGrpIndex);
    }

    setBoxGroups(newGroups);
    onDragEndPlaceholder();
  };

  const onDragUpdate: OnDragUpdateResponder = (update, provided) => {
    onDragUpdatePlaceholder(update, provided);
  };

  useEffect(() => {
    //set temp data when entering edit
    if (gridMode.isRearrangeMode) {
      setSnapshotBoxGroups(boxGroups);
    } else if (snapshotBoxGroups) {
      setBoxGroups(snapshotBoxGroups); //snapshot exists when canceling out
      setSnapshotBoxGroups(undefined);
    }
  }, [gridMode.mode]); //eslint-disable-line react-hooks/exhaustive-deps

  const onReorderSave = () => {
    const updatedBoxGroups = produce(boxGroups, draftState => {
      for (let i = 0; i < draftState.length; i++) {
        const group = draftState[i];

        for (let j = 0; j < group.items.length; j++) {
          const agendaItem = group.items[j];

          agendaItem.value = group.value;
          agendaItem.focus = group.focus;
          agendaItem.order = j + 1;
        }
      }
    });

    updateAgendaItems({ groups: updatedBoxGroups });
    setSnapshotBoxGroups(undefined);

    navigate(pages.grid.edit.go());
  };

  const containerClassName = classNames("boxes-container");

  return (
    <div className={namespace}>
      <AgendaGridPageHeader
        onExportClick={props.onExportClick}
        onReorderSave={onReorderSave}
      />
      <ActivityLogV2 />
      <AgendaGridAxis low="Regular Cadence" label="Focus" high="Immediate" />
      <div className="axis-and-boxes-container">
        <AgendaGridAxisVertical
          low="Lower"
          label="Value at Stake"
          high="Higher"
        />
        <Loader status={status}>
          <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
            <div className={containerClassName}>
              {boxGroups.map(g => (
                <AgendaGridBox
                  key={`${g.focus}${g.value}`}
                  focus={g.focus}
                  value={g.value}
                  items={g.items}
                />
              ))}
            </div>
          </DragDropContext>
        </Loader>
      </div>
    </div>
  );
};

export default AgendaGrid;
