import { PermissionRoleValidator } from "api/common/permission";
import { Array, Number, Record, Static, String } from "runtypes";

export const CalendarListItemValidator = Record({
  calendarId: Number,
  title: String,
  role: PermissionRoleValidator
});

export type CalendarListItem = Static<typeof CalendarListItemValidator>;

export const CalendarListValidator = Record({
  calendars: Array(CalendarListItemValidator)
});

export type CalendarList = Static<typeof CalendarListValidator>;
