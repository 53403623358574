import React from "react";
import "./KeyEventsCalendarHeader.scss";
import { Label } from "common-components";
import {
  SettingsCogIcon,
  theme
} from "@opsdti-global-component-library/amgen-design-system";
import classNames from "classnames";
import { Columns } from "../calendarTypes";
import { useNavigate } from "react-router-dom";
import pages from "pages/pages";
import KeyEventsCalendarPublishedDate from "../published-date/KeyEventsCalendarPublishedDate";

const namespace = "rts-pa-key-events-calendar-header";

type Props = {
  columns: Columns;
};

export default function KeyEventsCalendarHeader(props: Props): JSX.Element {
  const navigate = useNavigate();
  const { token } = theme;

  return (
    <div className={namespace}>
      <div className={`${namespace}-left-container`}>
        <KeyEventsCalendarPublishedDate />
        <SettingsCogIcon
          className={`${namespace}-icon`}
          height={14}
          onClick={() => navigate(pages.keyEvents.editCalendarModal.go())}
        />
      </div>
      <div
        className={`${namespace}-right-container`}
        style={{
          gridTemplateColumns: props.columns.gridTemplateColumns
        }}
      >
        {props.columns.years.map((y, i) => {
          const className = classNames("year-container", {
            last: props.columns.years.length - 1 === i
          });

          const gridColumn = `y${y.year}q${y.quarters[0]} / y${y.year + 1}q1`;

          return (
            <div
              className={className}
              key={y.year}
              style={{
                gridColumn: gridColumn,
                backgroundColor: token?.colorPrimaryBg
              }}
            >
              <Label colorVariant="dark">{y.year}</Label>
            </div>
          );
        })}
        {props.columns.years.map((y, yi) => {
          return y.quarters.map((q, qi) => {
            const className = classNames("quarter-container", {
              last:
                props.columns.years.length - 1 === yi &&
                y.quarters.length - 1 === qi
            });

            return (
              <div
                key={`${y.year}-${q}`}
                className={className}
                data-quarter={q}
                style={{
                  backgroundColor: token?.colorBorderSecondary
                }}
              >
                <Label colorVariant="dark">Q{q}</Label>
              </div>
            );
          });
        })}
      </div>
    </div>
  );
}
