import { Array, Boolean, Number, Record, Static, String } from "runtypes";
import { CalendarMilestoneValidator } from "./calendar-common";

export const CalendarBoardRowItemValidator = Record({
  id: Number,
  calendarRowVersionId: Number,
  calendarRowLinkId: String.nullable(),
  order: Number,
  name: String,
  isVisibleToOfficers: Boolean,
  linkToSource: String.nullable(),
  milestones: Array(CalendarMilestoneValidator)
});

export type CalendarBoardRowItem = Static<typeof CalendarBoardRowItemValidator>;

export const CalendarBoardValidator = Record({
  rows: Array(CalendarBoardRowItemValidator)
});

export type CalendarBoard = Static<typeof CalendarBoardValidator>;
