import React, { useContext } from "react";
import "./KeyEventsPage.scss";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PermissionsContext } from "global/permissions";
import { Container } from "@gitlab-rtsensing/component-library";
import KeyEventsPageHeader from "./header/KeyEventsPageHeader";
import KeyEventsCalendar from "components/key-events-calendar/KeyEventsCalendar";
import UpsertCalendarModal from "components/upsert-calendar-modal/UpsertCalendarModal";
import UpsertCalendarRowModal from "components/upsert-calendar-row-modal/UpsertCalendarRowModal";

const namespace = "rts-pa-key-events-page";

export default function KeyEventsPage(): JSX.Element {
  const isLoaded = useContext(PermissionsContext).isLoaded;
  // const perms = useContext(PermissionsContext).grid;

  return (
    <>
      <div className={namespace}>
        <PermissionsWrapper permissions={isLoaded}>
          <Container className={`${namespace}-container`}>
            <KeyEventsPageHeader />
            <KeyEventsCalendar />
          </Container>
        </PermissionsWrapper>
      </div>
      <PermissionsWrapper permissions={isLoaded}>
        <UpsertCalendarRowModal />
        <UpsertCalendarModal />
      </PermissionsWrapper>
    </>
  );
}
