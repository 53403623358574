import React from "react";
import ModalSection from "common-components/modal-section/ModalSection";
import EsgTotalCarbonWasteWaterReductionOffsetV2 from "./total-carbon-waste-water-reduction-offset/EsgTotalCarbonWasteWaterReductionOffset";
import { EnvironmentalSustainability } from "api/esg-item/esg";
import "./EnvironmentalSustainabilityMetrics.scss";

type Props = {
  data?: EnvironmentalSustainability;
};

const namespace =
  "rts-pa-agenda-esg-modal-environmental-sustainability-metrics";

const EnvironmentalSustainabilityMetrics = ({ data }: Props) => {
  if (!data) {
    return <></>;
  }

  return (
    <ModalSection
      className={namespace}
      title="Environmental Sustainability Metrics"
    >
      <div className={`${namespace}-container`}>
        <EsgTotalCarbonWasteWaterReductionOffsetV2
          data={data.totalCarbonWasteWaterReductionOffset}
        />
      </div>
    </ModalSection>
  );
};
export default EnvironmentalSustainabilityMetrics;
