import axios from "axios";
import { Literal, Record, Static, String } from "runtypes";

export enum CheckoutStatus {
  locked = "locked",
  unlocked = "unlocked"
}

const UserValidator = Record({
  email: String
});

const LockedStateValidator = Record({
  status: Literal(CheckoutStatus.locked),
  user: UserValidator
});

const UnlockedStateValidator = Record({
  status: Literal(CheckoutStatus.unlocked)
});

const CheckoutStatusValidator = LockedStateValidator.Or(UnlockedStateValidator);

export type CheckoutStatusResult = Static<typeof CheckoutStatusValidator>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const testUnlockedResponse: CheckoutStatusResult = {
  status: CheckoutStatus.unlocked
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const testLockedResponse: CheckoutStatusResult = {
  status: CheckoutStatus.locked,
  user: {
    email: "jgonza402@amgen.com"
  }
};

export const checkout = async (): Promise<CheckoutStatusResult> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<CheckoutStatusResult>(
    `${REACT_APP_DOMAIN}/api/v1/conflict_management/checkout`
  );

  // const result = {
  //   data: testUnlockedResponse
  // };

  CheckoutStatusValidator.check(result.data);

  return result.data;
};

export const checkin = async (): Promise<CheckoutStatusResult> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<CheckoutStatusResult>(
    `${REACT_APP_DOMAIN}/api/v1/conflict_management/checkin`
  );

  // const result = {
  //   data: testUnlockedResponse
  // };

  CheckoutStatusValidator.check(result.data);

  return result.data;
};

export const getCheckoutStatus = async (): Promise<CheckoutStatusResult> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<CheckoutStatusResult>(
    `${REACT_APP_DOMAIN}/api/v1/conflict_management/status`
  );

  // const result = {
  //   data: testLockedResponse
  // };

  CheckoutStatusValidator.check(result.data);

  return result.data;
};
