import React, { useContext } from "react";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PermissionsContext } from "global/permissions";
import "./KeyEventsPageHeader.scss";
import { Title } from "common-components";
import pages from "pages/pages";
import ToggleButton from "common-components/toggle-button/ToggleButton";
import useLimitedView from "global/use-limited-view";
import Button from "common-components/button/Button";
import { PlusIcon } from "@opsdti-global-component-library/amgen-design-system";
import CalendarKeys from "components/calendar-keys/CalendarKeys";
import { useNavigate } from "react-router-dom";

const namespace = "rts-pa-key-events-page-header";

export default function AgendaGridPageHeader(): JSX.Element {
  const perms = useContext(PermissionsContext).keyEvents;
  const { isLimitedView, toggleLimitedView } = useLimitedView(pages.keyEvents);
  const navigate = useNavigate();

  return (
    <div className={namespace}>
      <div className={`${namespace}-row`}>
        <div className={`${namespace}-left-container`}>
          <Title level={1}>Forward 12 Quarter Calendar</Title>
        </div>
        <div className={`${namespace}-right-container`}>
          <PermissionsWrapper permissions={perms.edit}>
            <ToggleButton
              firstLabel="FULL VIEW"
              secondLabel="LIMITED VIEW"
              checked={isLimitedView}
              onClick={toggleLimitedView}
            />
          </PermissionsWrapper>
        </div>
      </div>
      <div className={`${namespace}-row`}>
        <div className={`${namespace}-left-container`}>
          <Button
            text="Add Row"
            icon={<PlusIcon height={14} />}
            onClick={() => navigate(pages.keyEvents.addItemModal.go())}
          />
        </div>
        <div className={`${namespace}-right-container`}>
          <CalendarKeys />
        </div>
      </div>
    </div>
  );
}
