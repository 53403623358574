import {
  AdvancedTooltipValidator,
  ChartItemValidator,
  StatusValidator
} from "api/common";
import { LinkValidator } from "api/common-v2";
import { Array, Boolean, Record, Static, String } from "runtypes";

const TalentDinbTalentAmgenOverallEngagementValidator = Record({
  status: StatusValidator,
  title: String,
  tooltip: AdvancedTooltipValidator,
  chart: Array(ChartItemValidator),
  links: Array(LinkValidator),
  statusDriver: Boolean
});

export type TalentDinbTalentAmgenOverallEngagement = Static<
  typeof TalentDinbTalentAmgenOverallEngagementValidator
>;

const TalentDinbTalentAmgenTotalTurnoverValidator = Record({
  status: StatusValidator,
  title: String,
  tooltip: AdvancedTooltipValidator,
  chartTooltip: AdvancedTooltipValidator,
  chart: Array(ChartItemValidator),
  links: Array(LinkValidator),
  statusDriver: Boolean
});

export type TalentDinbTalentAmgenTotalTurnover = Static<
  typeof TalentDinbTalentAmgenTotalTurnoverValidator
>;

const TalentDinbAmgenRevenueValidator = Record({
  status: StatusValidator,
  tooltip: AdvancedTooltipValidator,
  chartTooltip: AdvancedTooltipValidator,
  chart: Array(ChartItemValidator),
  links: Array(LinkValidator)
});

export type TalentDinbTalentAmgenRevenue = Static<
  typeof TalentDinbAmgenRevenueValidator
>;

export const TalentDinbTalentSectionValidator = Record({
  amgenOverallEngagement:
    TalentDinbTalentAmgenOverallEngagementValidator.nullable(),
  amgenTotalTurnover: TalentDinbTalentAmgenTotalTurnoverValidator.nullable(),
  amgenRevenue: TalentDinbAmgenRevenueValidator.nullable()
});

export type TalentDinbTalentSection = Static<
  typeof TalentDinbTalentSectionValidator
>;
