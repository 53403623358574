import axios from "axios";
import {
  CalendarBoard,
  CalendarBoardValidator
} from "./calendar/calendar-board";
import { getKeyEventsId } from "utils/multitenancy";

export const getCalendarBoard = async (
  limitedView: boolean
): Promise<CalendarBoard> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<CalendarBoard>(
    `${REACT_APP_DOMAIN}/api/v1/${getKeyEventsId()}/calendar_row/board`,
    {
      params: {
        limited_view: limitedView
      }
    }
  );

  CalendarBoardValidator.check(result.data);

  return result.data;
};
