import React from "react";
import "./TalentDinbModalDinbWomenInLeadership.scss";
import { formatPercentage } from "utils/format";
import { Label, Text, Title } from "common-components";
import { TalentDinbDiversityInclusionBelongingWomenInLeadership } from "api/talent-dinb/talent-dinb-diversity-inclusion-belonging";
import { AccordionWithStatusV2 } from "common-components/accordion-with-status-v2/AccordionWithStatusV2";

type Props = {
  data:
    | TalentDinbDiversityInclusionBelongingWomenInLeadership
    | undefined
    | null;
};

const TalentDinbModalDinbWomenInLeadership = (props: Props) => {
  const namespace = "rts-pa-talent-dinb-modal-dinb-women-in-leadership";
  const title = "Women In Leadership";

  if (!props.data) {
    return <></>;
  }

  return (
    <AccordionWithStatusV2
      status={props.data.status}
      title={title}
      className={namespace}
      accordionSyncKey="TalentModal"
      links={props.data.links}
    >
      <div className={`${namespace}-metrics`}>
        <Text>Women in Executive Level Positions (GCF 8+)</Text>
        <div className={`${namespace}-metric`}>
          <Label>AMGEN</Label>
          <Title level={2}>{formatPercentage(props.data.executive)}</Title>
        </div>
        <Text>Women in Management Level Positions (GCF 6 & 7)</Text>
        <div className={`${namespace}-metric`}>
          <Label>AMGEN</Label>
          <Title level={2}>{formatPercentage(props.data.management)}</Title>
        </div>
      </div>
    </AccordionWithStatusV2>
  );
};

export default TalentDinbModalDinbWomenInLeadership;
