import { useState, useEffect } from "react";
import { QueryStatus, useQuery } from "@tanstack/react-query";
import { getConfigLinks } from "api/agendaConfig";
import { NavLink } from "@amgen/rtsensing-nav";
import { Permissions } from "global/permissions";
import produce from "immer";
import pages from "pages/pages";
import { isMultitenancy } from "utils/multitenancy";
import { getGlobalConfig } from "api/globalConfig";

export type PaNavLinks = {
  globalConfig: NavLink;
  updateHomepageImage: NavLink;
  ceoStaff: NavLink;
  functionalPa: NavLink;
  operatingTeam: NavLink;
};

const initialPaNavLinks: PaNavLinks = {
  globalConfig: {
    label: "Global Config",
    url: "",
    disabled: false
  },
  updateHomepageImage: {
    label: "Update Homepage Image",
    url: "",
    disabled: false
  },
  ceoStaff: {
    label: "CEO Staff Prioritized Agenda",
    url: "",
    disabled: false
  },
  functionalPa: {
    label: "Functional Prioritized Agendas",
    url: "",
    disabled: false
  },
  operatingTeam: {
    label: "2024 Operating Team GMIP & Deliverables",
    url: "",
    disabled: false
  }
};

interface CommonConfigLinksValidator {
  ceoStaffUrl: string;
  functionalPaUrl: string;
  operatingTeamUrl: string;
}

const fetchConfigLinks = async (): Promise<CommonConfigLinksValidator> => {
  if (isMultitenancy) {
    const globalConfig = await getGlobalConfig();
    // Map GlobalConfig to CommonConfig
    return {
      ceoStaffUrl: globalConfig.ceoStaffUrl,
      functionalPaUrl: globalConfig.functionalPaUrl,
      operatingTeamUrl: globalConfig.operatingTeamUrl
    };
  } else {
    const agendaConfig = await getConfigLinks();
    // Map AgendaConfigLinks to CommonConfig
    return {
      ceoStaffUrl: agendaConfig.ceoStaff,
      functionalPaUrl: agendaConfig.functionalPa,
      operatingTeamUrl: agendaConfig.operatingTeam
    };
  }
};

export function useNavLinks(permissions: Permissions) {
  const [paNavLinks, setPaNavLinks] = useState<{
    status: QueryStatus;
    links: PaNavLinks;
  }>({ status: "loading", links: initialPaNavLinks });

  const { status, data } = useQuery(["global-config"], fetchConfigLinks);

  useEffect(() => {
    if (status !== "success" || !data || !permissions) {
      return;
    }

    const nextState = produce(paNavLinks, draftState => {
      draftState.status = "success";

      draftState.links.globalConfig.disabled =
        !permissions.leftNavLinks.globalConfig || !isMultitenancy;
      draftState.links.globalConfig.url = pages.grid.globalConfigModal.go();

      draftState.links.updateHomepageImage.disabled =
        !permissions.leftNavLinks.updateHomepageImage;
      draftState.links.updateHomepageImage.url =
        pages.grid.updateHomepageImageModal.go();

      draftState.links.ceoStaff.disabled = !permissions.leftNavLinks.ceoStaff;
      draftState.links.ceoStaff.url = data.ceoStaffUrl;

      draftState.links.functionalPa.disabled =
        !permissions.leftNavLinks.functionalPas;
      draftState.links.functionalPa.url = data.functionalPaUrl;

      draftState.links.operatingTeam.disabled =
        !permissions.leftNavLinks.operatingTeam;
      draftState.links.operatingTeam.url = data.operatingTeamUrl;
    });

    setPaNavLinks(nextState);
  }, [status, data, permissions, paNavLinks]);

  return paNavLinks;
}
