import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import "./StandaloneModal.scss";
import PaModal from "common-components/pa-modal/PaModal";
import OwnersContainerV2 from "common-components/owners-container-v2/OwnersContainerV2";
import SummaryInfo from "common-components/summary-info/SummaryInfo";
import { useNavigate } from "react-router-dom";
import pages from "pages/pages";
import { PermissionsContext } from "global/permissions";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import SubmissionRequestV2 from "common-components/submission-request-v2/SubmissionRequestV2";
import ModalExternalLinksV2 from "common-components/modal-external-links-v2/ModalExternalLinksV2";
import { useModalHelper } from "global/use-modal-helper";
import { AgendaItemWithDetailsV2, getAgendaItemV2 } from "api/agendaItemV2";

const StandaloneModal = () => {
  const perms = useContext(PermissionsContext);
  const hasPermissions = perms.grid.standaloneModal;
  const navigate = useNavigate();
  const mhResult = useModalHelper(
    pages.grid.standaloneModal.path,
    hasPermissions,
    "standalone"
  );

  const { status, data, error } = useQuery<AgendaItemWithDetailsV2>(
    ["agenda-item", mhResult?.itemId],
    () => {
      return getAgendaItemV2(mhResult.itemId);
    },
    {
      enabled: mhResult.enabled
    }
  );

  const namespace = "rts-pa-standalone-modal-v2";

  return (
    <PaModal
      title={mhResult.title}
      status={status}
      axiosErrors={error}
      isOpen={mhResult.enabled}
      onClose={() => {
        navigate(pages.grid.go());
      }}
      className={namespace}
    >
      <PermissionsWrapper permissions={perms.submissions}>
        <SubmissionRequestV2 agendaItem={mhResult.agendaItem} />
      </PermissionsWrapper>
      <SummaryInfo data={data?.summaryInfo} />
      <OwnersContainerV2 owners={data?.owners} />
      <ModalExternalLinksV2
        links={data?.links}
        className="standalone-modal"
        buttonType="primary"
      />
    </PaModal>
  );
};

export default StandaloneModal;
