import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { RouteObject, matchRoutes, useLocation } from "react-router-dom";
import {
  PermissionsCollection,
  hasPermissions
} from "common-components/permissions-wrapper/permissions-wrapper";
import { AgendaItemV2, ArchetypeV2 } from "api/common-v2";
import { getAgendaItemsV2 } from "api/getAgendaItemsV2";
import { getCalendarBoard } from "api";
import { getAgendaId, getKeyEventsId } from "utils/multitenancy";
import { CalendarBoardRowItem } from "api/calendar/calendar-board";

type Result = {
  itemId: number;
  agendaItem: AgendaItemV2;
  calendarRow: CalendarBoardRowItem;
  title: string;
  path: string;
  enabled: boolean;
};

const defaultResponse: Result = {
  itemId: 0,
  agendaItem: {} as AgendaItemV2,
  calendarRow: {} as CalendarBoardRowItem,
  title: "",
  path: "",
  enabled: false
};

export function useModalHelper(
  paths: string | string[],
  permissions: PermissionsCollection, //NOTE: if passing in array, will map perms array index to path index for validation
  archetype?: ArchetypeV2
): Result {
  const [response, setResponse] = useState<Result>(defaultResponse);

  const agendaId = getAgendaId();
  const keyEventsId = getKeyEventsId();

  const { data: agendaItems } = useQuery(
    ["agenda-items", false],
    () => getAgendaItemsV2(false),
    { enabled: agendaId !== "0" }
  );

  const { data: calendarBoard } = useQuery(
    ["calendar-board", false],
    () => getCalendarBoard(false),
    { enabled: keyEventsId !== "0" }
  );

  const location = useLocation();

  useEffect(() => {
    const returnDefault = () => {
      if (!response.enabled) {
        return; //already default
      }

      setResponse({
        ...response,
        enabled: false
      });

      setTimeout(() => {
        setResponse(defaultResponse);
      }, 500);
    };

    // check perms now, if both perms+path are not arrays (if both arrays, check perms based on matching path)
    if (
      !Array.isArray(permissions) ||
      (Array.isArray(permissions) && !Array.isArray(paths))
    ) {
      if (!hasPermissions(permissions)) {
        return returnDefault();
      }
    }

    //check paths
    let routes: RouteObject[];
    if (Array.isArray(paths)) {
      routes = paths.map(p => ({ path: p }));
    } else {
      routes = [{ path: paths }];
    }

    const matches = matchRoutes(routes, location);
    if (!matches) {
      return returnDefault();
    }

    const path = matches[0].route.path || "";

    //if permissions are array, check permissions based on path-matching-index
    if (Array.isArray(permissions)) {
      const pathIndex = paths.indexOf(path);

      if (pathIndex >= 0) {
        if (permissions.length - 1 < pathIndex) {
          throw new Error(
            "In useModalHelper, if passing in an array for both permissions and paths, they must match in length"
          );
        } else if (!hasPermissions(permissions[pathIndex])) {
          return returnDefault();
        }
      }
    }

    if (agendaId !== "0") {
      const { agendaItemId } = matches[0].params;

      //get agendaItemId
      let agendaItemIdNum: number;
      if (!agendaItemId) {
        //ok if no agendaItemId needed
        return setResponse({
          ...defaultResponse,
          path: path,
          enabled: true
        });
      } else {
        agendaItemIdNum = parseInt(agendaItemId);

        //if agendaItemId is NaN, ignore request
        if (Number.isNaN(agendaItemIdNum)) {
          return returnDefault();
        }
      }

      //check agendaItemId exists on grid
      if (!agendaItems) {
        return returnDefault();
      }

      for (const group of agendaItems.groups) {
        for (const item of group.items) {
          if (item.id === agendaItemIdNum) {
            //if wrong archetype, ignore request
            if (archetype && item.archetype !== archetype) {
              return returnDefault();
            } else {
              //success
              return setResponse({
                ...defaultResponse,
                agendaItem: item,
                itemId: item.id,
                title: item.name,
                path: path,
                enabled: true
              });
            }
          }
        }
      }
    } else if (keyEventsId !== "0") {
      const { calendarRowId } = matches[0].params;

      //get agendaItemId
      let calendarRowIdNum: number;
      if (!calendarRowId) {
        //ok if no calendarRowId needed
        return setResponse({
          ...defaultResponse,
          path: path,
          enabled: true
        });
      } else {
        calendarRowIdNum = parseInt(calendarRowId);

        //if agendaItemId is NaN, ignore request
        if (Number.isNaN(calendarRowIdNum)) {
          return returnDefault();
        }
      }

      //check agendaItemId exists on grid
      if (!calendarBoard) {
        return returnDefault();
      }

      for (const row of calendarBoard.rows) {
        if (row.id === calendarRowIdNum) {
          return setResponse({
            ...defaultResponse,
            calendarRow: row,
            itemId: row.id,
            title: row.name,
            path: path,
            enabled: true
          });
        }
      }
    }

    returnDefault();
  }, [location, agendaItems, calendarBoard]); // eslint-disable-line react-hooks/exhaustive-deps

  return response;
}
