import React, {
  FC,
  PropsWithChildren,
  createContext,
  useState,
  useEffect
} from "react";
import { useQuery } from "@tanstack/react-query";
import {
  AgendaList,
  Authorization,
  getAgendaList,
  getAuthorization
} from "api";
import { useNavManager } from "global/use-nav-manager";
import pages from "pages/pages";
import { useNavigate } from "react-router-dom";
import { getAgendaId, isMultitenancy } from "utils/multitenancy";

export type Permissions = {
  isLoaded: boolean;
  homeTile: {
    any: boolean;
    pdfs: boolean;
    pageLink: boolean;
  };
  grid: {
    any: boolean;
    edit: boolean;
    status: boolean;
    view: boolean;
    create: boolean;
    standaloneModal: boolean;
    commercialModal: boolean;
    pipelineModal: boolean;
    talentDinbModal: boolean;
    horizonModal: boolean;
    esgModal: boolean;
    biosimilarsModal: boolean;
    export: boolean;
    activityLog: boolean;
  };
  leftNavLinks: {
    any: boolean;
    agendaConfig: boolean;
    globalConfig: boolean;
    updateHomepageImage: boolean;
    ceoStaff: boolean;
    functionalPas: boolean;
    operatingTeam: boolean;
  };
  submissions: boolean;
  changeLog: boolean;
  keyEvents: {
    any: boolean;
    view: boolean;
    edit: boolean;
  };
};

const initialPermissions: Permissions = {
  isLoaded: false,
  homeTile: {
    any: false,
    pdfs: false,
    pageLink: false
  },
  grid: {
    any: false,
    edit: false,
    status: false,
    view: false,
    create: false,
    standaloneModal: false,
    commercialModal: false,
    pipelineModal: false,
    talentDinbModal: false,
    horizonModal: false,
    esgModal: false,
    biosimilarsModal: false,
    export: false,
    activityLog: false
  },
  leftNavLinks: {
    any: false,
    agendaConfig: false,
    globalConfig: false,
    updateHomepageImage: false,
    ceoStaff: false,
    functionalPas: false,
    operatingTeam: false
  },
  submissions: false,
  changeLog: false,
  keyEvents: {
    any: false,
    view: false,
    edit: false
  }
};

export const PermissionsContext = createContext(initialPermissions);

export const PermissionsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [permissions, setPermissions] = useState(initialPermissions);
  useNavManager(permissions);
  const navigate = useNavigate();

  const {
    status,
    data: permsResponse,
    refetch
  } = useQuery<Authorization>(["authorization"], getAuthorization);

  const { status: agendaListStatus, data: agendaList } = useQuery<AgendaList>(
    ["agenda-list"],
    getAgendaList,
    {
      enabled: isMultitenancy
    }
  );

  useEffect(() => {
    if (status !== "success" || agendaListStatus !== "success") {
      return;
    }

    const auth = permsResponse.pa;

    const perms: Permissions = {
      isLoaded: true,
      homeTile: {
        any: false,
        pdfs: auth.homePdf,
        pageLink: permsResponse.ws.pa
      },
      grid: {
        any: false,
        edit: auth.gridAdmin,
        status: auth.gridStatus,
        view: auth.gridReader,
        create: auth.createAgenda,
        standaloneModal: auth.gridStatus,
        commercialModal: auth.commercial,
        pipelineModal: auth.pipeline,
        talentDinbModal: auth.nonProduct,
        horizonModal: auth.commercial,
        esgModal: auth.nonProduct,
        biosimilarsModal: auth.pipeline,
        export: auth.export,
        activityLog: auth.activityLog
      },
      leftNavLinks: {
        any: false,
        agendaConfig: auth.gridAdmin,
        globalConfig: auth.superAdmin,
        updateHomepageImage: auth.superAdmin,
        ceoStaff: auth.ceoStaffNav,
        functionalPas: auth.funcNav,
        operatingTeam: auth.gridStatus
      },
      submissions: auth.gridAdmin && getAgendaId() === "1",
      changeLog: auth.changeLog,
      keyEvents: {
        any: false,
        view: true,
        edit: true
      }
    };

    // iterates through perms and children perms setting `.any` prop based on sibling property `true` values
    const setAny = (obj: { [key: string]: object | boolean }): void => {
      let key: string;
      let hasAnyProp = false;
      let hasAny = false;

      const nonBoolProps: string[] = [];

      for (key in obj) {
        if (key === "any") {
          hasAnyProp = true;
          continue;
        }

        if (typeof obj[key] === "object") {
          nonBoolProps.push(key);
        } else if (obj[key] === true) {
          hasAny = true;
        }
      }

      if (hasAnyProp) {
        obj["any"] = hasAny; // eslint-disable-line no-param-reassign
      }

      for (const childObjKey of nonBoolProps) {
        const newObj = obj[childObjKey] as { [key: string]: object | boolean };
        setAny(newObj);
      }
    };

    setAny(perms);

    if (!perms.grid.view) {
      const agendaId = agendaList.agendas[0]?.agendaId.toString();

      if (agendaId) {
        navigate(pages.grid.go(agendaId));
        setTimeout(() => {
          refetch();
        }, 100);
      } else {
        // TODO JCG: also check if access to any calendars, and reroute to that instead
        navigate(pages.unauthorized.go());
      }
    } else {
      setPermissions(perms);
    }
  }, [status, permsResponse, agendaListStatus, agendaList, navigate, refetch]);

  return (
    <PermissionsContext.Provider value={permissions}>
      {children}
    </PermissionsContext.Provider>
  );
};
