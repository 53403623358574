import { EnvironmentalSustainabilityValidator } from "./esg-item/esg";
import {
  SummaryInfoValidator,
  OwnersValidator,
  LinkValidator,
  ArchetypeValidatorV2
} from "./common-v2";
import { Record, Number, Array, Static, String } from "runtypes";

export const EsgItemValidator = Record({
  id: Number,
  archetype: ArchetypeValidatorV2,
  agendaItemVersionId: Number,
  statusDrivers: Array(String),
  triggers: Array(String),
  summaryInfo: SummaryInfoValidator,
  owners: OwnersValidator,
  environmentalSustainability: EnvironmentalSustainabilityValidator,
  links: Array(LinkValidator)
});

export type EsgItem = Static<typeof EsgItemValidator>;
