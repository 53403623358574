import React from "react";
import "./TalentDinbModalTalentAmgenTotalTurnover.scss";
import { AdvancedTooltip } from "common-components/advanced-tooltip/AdvancedTooltip";
import {
  ComposedLineChartComponent,
  LineType
} from "@gitlab-rtsensing/component-library";
import { colors } from "utils";
import { formatPercentage } from "utils/format";
import getAdvancedTooltipContent from "common-components/advanced-tooltip/getAdvancedTooltipContent";
import { Label } from "common-components";
import { TalentDinbTalentAmgenTotalTurnover } from "api/talent-dinb/talent-dinb-talent";
import { AccordionWithStatusV2 } from "common-components/accordion-with-status-v2/AccordionWithStatusV2";

type Props = {
  data: TalentDinbTalentAmgenTotalTurnover | undefined | null;
};

const TalentDinbModalTalentAmgenTotalTurnover = (props: Props) => {
  const namespace = "rts-pa-talent-dinb-modal-talent-amgen-total-turnover";
  const title = "Amgen Total Turnover";

  if (!props.data) {
    return <></>;
  }

  const chartData = props.data.chart.map(item => ({
    label: item.label || undefined,
    forecast: item.forecast !== null ? item.forecast : undefined,
    value: item.value !== null ? item.value : undefined
  }));

  return (
    <AccordionWithStatusV2
      status={props.data.status}
      title={title}
      tooltipContent={getAdvancedTooltipContent(props.data.tooltip)}
      className={namespace}
      accordionSyncKey="TalentModal"
      links={props.data.links}
    >
      {props.data.chartTooltip && (
        <div className={`${namespace}-header`}>
          <Label>12 Month Rolling Average</Label>
          <AdvancedTooltip tooltip={props.data.chartTooltip} />
        </div>
      )}
      <div className={`${namespace}-metrics`}>
        <ComposedLineChartComponent
          data={chartData}
          xAxisKey="label"
          dataKeys={[
            { label: "value", dot: true, color: colors.primaryBlue },
            {
              label: "forecast",
              dot: false,
              color: colors.pinkAccent,
              type: LineType.SOLID,
              strokeWidth: 2
            }
          ]}
          dataKeyFormatter={(key: string | number) =>
            key === "value" ? "AMGEN" : "LIFE SCIENCES BENCHMARK"
          }
          dataValueFormatter={(
            value: string | number | Array<string | number>
          ) => {
            if (Array.isArray(value)) {
              return "";
            }

            return formatPercentage(value);
          }}
          chartHeight={200}
          useTooltip
          showLegend
          yAxisWidth={25}
        />
      </div>
    </AccordionWithStatusV2>
  );
};

export default TalentDinbModalTalentAmgenTotalTurnover;
