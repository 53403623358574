import React, { useMemo } from "react";
import "./KeyEventsCalendar.scss";
import KeyEventsCalendarHeader from "./header/KeyEventsCalendarHeader";
import dayjs, { Dayjs } from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { getCalendarBoard } from "api/calendar-board";
import {
  Columns,
  MilestoneFixed,
  MilestoneRange,
  RowItem,
  Year
} from "./calendarTypes";
import KeyEventsCalendarRow from "./row/KeyEventsCalendarRow";
import useLimitedView from "global/use-limited-view";
import pages from "pages/pages";
import KeyEventsCalendarEmptyContainer from "./empty-container/KeyEventsCalendarEmptyContainer";
import { CalendarMilestone } from "api";

export const namespace = "rts-pa-key-events-calendar";

type QuarterYear = {
  year: number;
  quarter: number;
};

const getDateQuarterYear = (date: Dayjs): QuarterYear => {
  return {
    year: date.year(),
    quarter: Math.ceil((date.month() + 1) / 3)
  };
};

export default function KeyEventsCalendar(): JSX.Element {
  const { isLimitedView } = useLimitedView(pages.keyEvents);
  const { data } = useQuery(["calendar-board", isLimitedView], () =>
    getCalendarBoard(isLimitedView)
  );

  const columns: Columns = useMemo((): Columns => {
    const totalQuarters = 12;

    const qy = getDateQuarterYear(dayjs().tz());

    let yNum = qy.year;
    let qNum = qy.quarter;

    const years: Year[] = [{ year: yNum, quarters: [] }];
    let gridTemplateColumns = "";

    for (let i = 0; i < totalQuarters; i++) {
      let year: Year;
      if (qNum === 5) {
        year = {
          year: ++yNum,
          quarters: []
        };
        qNum = 1;
        years.push(year);
      }

      year = years[years.length - 1];
      year.quarters.push(qNum);
      gridTemplateColumns += ` [y${year.year}q${qNum++}] 1fr`;
    }

    return {
      years: years,
      gridTemplateColumns: gridTemplateColumns
    };
  }, []);

  const rows: RowItem[] = useMemo(() => {
    if (!data || !columns) {
      return [];
    }

    const rows: RowItem[] = [];

    for (const apiRow of data.rows) {
      // add row item
      const rowItem: RowItem = {
        id: apiRow.id,
        name: apiRow.name,
        order: apiRow.order,
        fixedMilestones: [],
        rangeMilestones: []
      };

      rows.push(rowItem);

      // create milestone mappers

      const mapToFixedMilestone = (
        apiMilestone: CalendarMilestone
      ): MilestoneFixed => {
        const startDate = dayjs(apiMilestone.startDate);

        const qy = getDateQuarterYear(startDate);

        const milestone: MilestoneFixed = {
          id: apiMilestone.id,
          milestoneCalendarKeyId: apiMilestone.milestoneCalendarKeyId,
          name: apiMilestone.name,
          text: apiMilestone.text,
          startYear: qy.year,
          startQuarter: qy.quarter
        };

        return milestone;
      };

      const mapToRangeMilestone = (
        apiMilestone: CalendarMilestone
      ): MilestoneRange => {
        const startDate = dayjs(apiMilestone.startDate);
        const endDate = dayjs(apiMilestone.endDate);

        let startQy = getDateQuarterYear(startDate);
        const endQy = getDateQuarterYear(endDate.add(3, "month")); // creates correct endYear/endQuarter for grid

        const min = columns.years[0];

        // correct min range
        if (
          startQy.year < min.year ||
          (startQy.year === min.year && startQy.quarter < min.quarters[0])
        ) {
          startQy = {
            year: min.year,
            quarter: min.quarters[0]
          };
        }

        // note: max range handled fine by CSS even when incorrect column name

        const milestone: MilestoneRange = {
          id: apiMilestone.id,
          milestoneCalendarKeyId: apiMilestone.milestoneCalendarKeyId,
          name: apiMilestone.name,
          text: apiMilestone.text,
          startYear: startQy.year,
          startQuarter: startQy.quarter,
          endYear: endQy.year,
          endQuarter: endQy.quarter
        };

        return milestone;
      };

      // map milestones to calendar row
      for (const apiMilestone of apiRow.milestones) {
        if (!apiMilestone.endDate) {
          // fixed
          rowItem.fixedMilestones.push(mapToFixedMilestone(apiMilestone));
        } else {
          // range
          rowItem.rangeMilestones.push(mapToRangeMilestone(apiMilestone));
        }
      }
    }

    return rows;
  }, [data, columns]);

  return (
    <div className={namespace}>
      <KeyEventsCalendarHeader columns={columns} />
      {rows.length ? (
        rows.map((r, i) => (
          <KeyEventsCalendarRow
            key={r.id}
            item={r}
            columns={columns}
            index={i}
          />
        ))
      ) : (
        <KeyEventsCalendarEmptyContainer />
      )}
    </div>
  );
}
