export const isMultitenancy = true;

const getId = (path: string): string => {
  const defaultId = "0";

  const gridPath = window.location.pathname.split(path)[1];

  if (!gridPath) {
    return defaultId;
  }

  const agendaIdStr = gridPath.split("/")[0];
  const agendaId = parseInt(agendaIdStr);

  if (Number.isNaN(agendaId)) {
    return defaultId;
  }

  return agendaIdStr;
};

export const getAgendaId = (): string => {
  return getId("/prioritized-agenda/grid/");
};

export const getKeyEventsId = (): string => {
  return getId("/prioritized-agenda/key-events/");
};
