import React, {
  FC,
  PropsWithChildren,
  createContext,
  useState,
  useEffect
} from "react";
import { useQuery } from "@tanstack/react-query";
import {
  AgendaList,
  Authorization,
  CalendarList,
  getAgendaList,
  getAuthorization,
  getCalendarList
} from "api";
import { useNavManager } from "global/use-nav-manager";
import pages from "pages/pages";
import { useNavigate } from "react-router-dom";
import { getAgendaId, getKeyEventsId } from "utils/multitenancy";

export type Permissions = {
  isLoaded: boolean;
  homeTile: {
    any: boolean;
    pdfs: boolean;
    pageLink: boolean;
  };
  grid: {
    any: boolean;
    edit: boolean;
    status: boolean;
    view: boolean;
    create: boolean;
    standaloneModal: boolean;
    commercialModal: boolean;
    pipelineModal: boolean;
    talentDinbModal: boolean;
    horizonModal: boolean;
    esgModal: boolean;
    biosimilarsModal: boolean;
    export: boolean;
    activityLog: boolean;
  };
  leftNavLinks: {
    any: boolean;
    agendaConfig: boolean;
    globalConfig: boolean;
    updateHomepageImage: boolean;
    ceoStaff: boolean;
    functionalPas: boolean;
    operatingTeam: boolean;
  };
  submissions: boolean;
  changeLog: boolean;
  keyEvents: {
    any: boolean;
    view: boolean;
    edit: boolean;
    create: boolean;
  };
};

const initialPermissions: Permissions = {
  isLoaded: false,
  homeTile: {
    any: false,
    pdfs: false,
    pageLink: false
  },
  grid: {
    any: false,
    edit: false,
    status: false,
    view: false,
    create: false,
    standaloneModal: false,
    commercialModal: false,
    pipelineModal: false,
    talentDinbModal: false,
    horizonModal: false,
    esgModal: false,
    biosimilarsModal: false,
    export: false,
    activityLog: false
  },
  leftNavLinks: {
    any: false,
    agendaConfig: false,
    globalConfig: false,
    updateHomepageImage: false,
    ceoStaff: false,
    functionalPas: false,
    operatingTeam: false
  },
  submissions: false,
  changeLog: false,
  keyEvents: {
    any: false,
    view: false,
    edit: false,
    create: false
  }
};

export const PermissionsContext = createContext(initialPermissions);

export const PermissionsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [permissions, setPermissions] = useState(initialPermissions);
  useNavManager(permissions);
  const navigate = useNavigate();

  const { data: permsResponse, refetch } = useQuery<Authorization>(
    ["authorization"],
    () => getAuthorization()
  );

  const { data: agendaList } = useQuery<AgendaList>(
    ["agenda-list"],
    getAgendaList
  );

  const { data: calendarList } = useQuery<CalendarList>(
    ["calendar-list"],
    getCalendarList
  );

  useEffect(() => {
    if (!permsResponse || !agendaList || !calendarList) {
      return;
    }

    let perms: Permissions = initialPermissions;

    if (permsResponse.pa) {
      const auth = permsResponse.pa;

      perms = {
        ...initialPermissions,
        isLoaded: true,
        homeTile: {
          any: false,
          pdfs: auth.homePdf,
          pageLink: permsResponse.ws.pa
        },
        grid: {
          any: false,
          edit: auth.gridAdmin,
          status: auth.gridStatus,
          view: auth.gridReader,
          create: auth.createAgenda,
          standaloneModal: auth.gridStatus,
          commercialModal: auth.commercial,
          pipelineModal: auth.pipeline,
          talentDinbModal: auth.nonProduct,
          horizonModal: auth.commercial,
          esgModal: auth.nonProduct,
          biosimilarsModal: auth.pipeline,
          export: auth.export,
          activityLog: auth.activityLog
        },
        leftNavLinks: {
          any: false,
          agendaConfig: auth.gridAdmin,
          globalConfig: auth.superAdmin,
          updateHomepageImage: auth.superAdmin,
          ceoStaff: auth.ceoStaffNav,
          functionalPas: auth.funcNav,
          operatingTeam: auth.gridStatus
        },
        submissions: auth.gridAdmin && getAgendaId() === "1",
        changeLog: auth.changeLog
      };
    } else if (permsResponse.ke) {
      const auth = permsResponse.ke;

      // TODO JCG: also handle leftNavLinks when looking at calendars
      perms = {
        ...initialPermissions,
        isLoaded: true,
        homeTile: {
          any: false,
          pdfs: false, // need to make "global" setting
          pageLink: permsResponse.ws.pa
        },
        keyEvents: {
          any: false,
          view: auth.calendarViewer || auth.officerViewer,
          edit: auth.calendarAdmin,
          create: auth.createCalendar
        }
      };
    }

    // iterates through perms and children perms setting `.any` prop based on sibling property `true` values
    const setAny = (obj: { [key: string]: object | boolean }): void => {
      let key: string;
      let hasAnyProp = false;
      let hasAny = false;

      const nonBoolProps: string[] = [];

      for (key in obj) {
        if (key === "any") {
          hasAnyProp = true;
          continue;
        }

        if (typeof obj[key] === "object") {
          nonBoolProps.push(key);
        } else if (obj[key] === true) {
          hasAny = true;
        }
      }

      if (hasAnyProp) {
        obj["any"] = hasAny; // eslint-disable-line no-param-reassign
      }

      for (const childObjKey of nonBoolProps) {
        const newObj = obj[childObjKey] as { [key: string]: object | boolean };
        setAny(newObj);
      }
    };

    setAny(perms);

    // reroute if needed -- usually on initial page load, other reroute in TenantSelector happens on list item removal
    const tryRoutingToGrid = () => {
      if (perms.grid.view) {
        setPermissions(perms);
        return true;
      }

      const agendaId = agendaList.agendas[0]?.agendaId.toString();

      if (agendaId) {
        navigate(pages.grid.go(agendaId));
        setTimeout(() => {
          refetch();
        }, 100);
        return true;
      }

      return false;
    };

    const tryRoutingToKeyEvents = () => {
      if (perms.keyEvents.view) {
        setPermissions(perms);
        return true;
      }

      const calendarId = calendarList.calendars[0]?.calendarId.toString();

      if (calendarId) {
        navigate(pages.keyEvents.go(calendarId));
        setTimeout(() => {
          refetch();
        }, 100);

        return true;
      }

      return false;
    };

    const agendaId = getAgendaId();
    const keyEventsId = getKeyEventsId();

    if (
      (agendaId !== "0" && !tryRoutingToGrid() && !tryRoutingToKeyEvents()) ||
      (keyEventsId !== "0" && !tryRoutingToKeyEvents() && !tryRoutingToGrid())
    ) {
      navigate(pages.unauthorized.go());
    } else if (agendaId === "0" && keyEventsId === "0") {
      //load "no context" perms, used in tiles
      setPermissions(perms);
    }
  }, [permsResponse, agendaList, calendarList]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PermissionsContext.Provider value={permissions}>
      {children}
    </PermissionsContext.Provider>
  );
};
