import React, { useState } from "react";
import "./LimitedViewManagement.scss";
import ToggleButton from "common-components/toggle-button/ToggleButton";
import useLimitedView from "global/use-limited-view";
import { Button } from "@opsdti-global-component-library/amgen-design-system";
import LimitedViewManagementModal from "./modal/LimitedViewManagementModal";
import { isMultitenancy } from "utils/multitenancy";
import LimitedViewManagementModalV2 from "./modal/LimitedViewManagementModalV2";
import pages from "pages/pages";

const namespace = "rts-pa-agenda-grid-limited-view-management";

export default function LimitedViewManagement(): JSX.Element {
  const { isLimitedView, toggleLimitedView } = useLimitedView(pages.grid);
  const [verifyPublish, setVerifyPublish] = useState(false);

  return (
    <div className={namespace}>
      <ToggleButton
        firstLabel="FULL VIEW"
        secondLabel="LIMITED VIEW"
        checked={isLimitedView}
        onClick={toggleLimitedView}
      />
      <Button
        text="Publish to Limited View"
        type="primary"
        disabled={isLimitedView}
        onClick={() => setVerifyPublish(true)}
      />
      {isMultitenancy ? (
        <LimitedViewManagementModalV2
          isOpen={verifyPublish}
          onClose={() => setVerifyPublish(false)}
        />
      ) : (
        <LimitedViewManagementModal
          isOpen={verifyPublish}
          onClose={() => setVerifyPublish(false)}
        />
      )}
    </div>
  );
}
