import axios from "axios";
import { Boolean, Record, Static } from "runtypes";
import { getAgendaId, getKeyEventsId } from "utils/multitenancy";

const WorkspacePermissionsValidator = Record({
  pa: Boolean,
  brand: Boolean,
  pipeline: Boolean,
  supply: Boolean,
  people: Boolean,
  finance: Boolean
});

const PaPermissionsValidator = Record({
  superAdmin: Boolean,
  gridAdmin: Boolean,
  gridStatus: Boolean,
  gridReader: Boolean,
  createAgenda: Boolean,

  commercial: Boolean,
  pipeline: Boolean,
  nonProduct: Boolean,

  export: Boolean,
  changeLog: Boolean,
  activityLog: Boolean,

  homePdf: Boolean,
  ceoStaffNav: Boolean,
  funcNav: Boolean
});

const KePermissionsValidator = Record({
  superAdmin: Boolean,
  calendarAdmin: Boolean,
  calendarViewer: Boolean,
  officerViewer: Boolean,
  createCalendar: Boolean
});

const AuthorizationValidator = Record({
  ws: WorkspacePermissionsValidator,
  pa: PaPermissionsValidator.nullable(),
  ke: KePermissionsValidator.nullable()
});

export type Authorization = Static<typeof AuthorizationValidator>;

export const getAuthorization = async (
  type?: "grid" | "keyEvents"
): Promise<Authorization> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  let resultData: Authorization;
  const agendaId = getAgendaId();
  const keyEventsId = getKeyEventsId();

  if (agendaId !== "0" || type === "grid") {
    const result = await axios.get<Authorization>(
      `${REACT_APP_DOMAIN}/api/v2/auth/grid/${agendaId}`
    );

    resultData = result.data;
  } else if (keyEventsId !== "0" || type === "keyEvents") {
    const result = await axios.get<Authorization>(
      `${REACT_APP_DOMAIN}/api/v2/auth/key_events/${keyEventsId}`
    );

    resultData = result.data;
  } else {
    const result = await axios.get<Authorization>(
      `${REACT_APP_DOMAIN}/api/v1/authorization`
    );

    resultData = result.data;
  }

  AuthorizationValidator.check(resultData);

  return resultData;
};
