import axios from "axios";
import { CalendarList, CalendarListValidator } from "./calendar/calendar-list";

export const getCalendarList = async (): Promise<CalendarList> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<CalendarList>(
    `${REACT_APP_DOMAIN}/api/v1/calendar/list`
  );

  CalendarListValidator.check(result.data);

  return result.data;
};
