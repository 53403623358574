import { Number, Record, Static, Array, String } from "runtypes";
import {
  ArchetypeValidatorV2,
  LinkValidator,
  OwnersValidator,
  SummaryInfoValidator
} from "./common-v2";
import { TalentDinbTalentSectionValidator } from "./talent-dinb/talent-dinb-talent";
import { TalentDinbDiversityInclusionBelongingSectionValidator } from "./talent-dinb/talent-dinb-diversity-inclusion-belonging";
import { TalentDinbLaborProductivitySectionValidator } from "./talent-dinb/talent-dinb-labor-productivity";

export const TalentDinbValidatorV2 = Record({
  agendaItemId: Number,
  archetype: ArchetypeValidatorV2,
  triggers: Array(String),
  statusDrivers: Array(String),
  summaryInfo: SummaryInfoValidator,
  owners: OwnersValidator,
  talent: TalentDinbTalentSectionValidator.nullable(),
  diversityInclusionBelonging:
    TalentDinbDiversityInclusionBelongingSectionValidator.nullable(),
  laborProductivity: TalentDinbLaborProductivitySectionValidator.nullable(),
  links: Array(LinkValidator)
});

export type TalentDinbV2 = Static<typeof TalentDinbValidatorV2>;
