import {
  Array,
  Boolean,
  Literal,
  Number,
  Record,
  Static,
  String,
  Union
} from "runtypes";

export const ArchetypeValidator = Union(
  Literal("Standalone"),
  Literal("Commercial"),
  Literal("Pipeline"),
  Literal("TalentDinb"),
  Literal("PS"), //Process Simplification
  Literal("NPBI"), //Non-Product Business Initiative
  Literal("Horizon"), //temporary as we have manual exception: Horizon; TODO JCG: remove when removing horizon modal
  Literal("ESG"),
  Literal("Biosimilar")
);

export type Archetype = Static<typeof ArchetypeValidator>;

export const StatusTypeValidator = Union(
  Literal("Manual"),
  Literal("Automatic")
);

export type StatusType = Static<typeof StatusTypeValidator>;

//#region AdvancedTooltip
const AdvancedTooltipTextValidator = Record({
  type: Literal("text"),
  value: String,
  style: Record({}).nullable()
});

const AdvancedTooltipTextWithoutStyleValidator =
  AdvancedTooltipTextValidator.omit("style");

export type AdvancedTooltipText = Static<
  typeof AdvancedTooltipTextWithoutStyleValidator
> & { style: null | React.CSSProperties };

const StatusIconTypesValidator = Union(
  Literal("accelerated"),
  Literal("green"),
  Literal("yellow"),
  Literal("red"),
  Literal("gray")
);

export type StatusIconTypes = Static<typeof StatusIconTypesValidator>;

const AdvancedTooltipIconOptionsValidator = Union(StatusIconTypesValidator);

const AdvancedTooltipIconValidator = Record({
  type: Literal("icon"),
  icon: AdvancedTooltipIconOptionsValidator
});

export type AdvancedTooltipIcon = Static<typeof AdvancedTooltipIconValidator>;

const AdvancedTooltipColumn2RowValidator = Record({
  leftContent: String,
  rightContent: String
});

export type AdvancedTooltipColumn2Row = Static<
  typeof AdvancedTooltipColumn2RowValidator
>;

const AdvancedTooltipColumn2Validator = Record({
  type: Literal("column-2"),
  rows: Array(AdvancedTooltipColumn2RowValidator)
});

export type AdvancedTooltipColumn2 = Static<
  typeof AdvancedTooltipColumn2Validator
>;

const AdvancedTooltipSpaceValidator = Record({
  type: Literal("space"),
  spaces: Number
});

export type AdvancedTooltipSpace = Static<typeof AdvancedTooltipSpaceValidator>;

export const AdvancedTooltipContentLineValidator = Union(
  String,
  AdvancedTooltipTextValidator,
  AdvancedTooltipIconValidator,
  AdvancedTooltipSpaceValidator
);

export type AdvancedTooltipLineContent = Static<
  typeof AdvancedTooltipContentLineValidator
>;

const AdvancedTooltipIconDefinitionValidator = Record({
  type: Literal("icon-definition"),
  icon: AdvancedTooltipIconOptionsValidator,
  definition: Array(AdvancedTooltipContentLineValidator)
});

export type AdvancedTooltipIconDefinition = Static<
  typeof AdvancedTooltipIconDefinitionValidator
>;

export const AdvancedTooltipContentValidator = Union(
  AdvancedTooltipContentLineValidator,
  AdvancedTooltipColumn2Validator,
  AdvancedTooltipIconDefinitionValidator
);

export type AdvancedTooltipContent = Static<
  typeof AdvancedTooltipContentValidator
>;

const AdvancedTooltipSectionValidator = Record({
  title: String.nullable().optional(),
  rowSpacing: Union(Literal("normal"), Literal("medium"), Literal("large"))
    .nullable()
    .optional(),
  rows: Array(
    Record({
      label: String.nullable().optional(),
      content: Array(AdvancedTooltipContentValidator)
    })
  )
});

export type AdvancedTooltipSection = Static<
  typeof AdvancedTooltipSectionValidator
>;

export const AdvancedTooltipValidator = Record({
  sections: Array(AdvancedTooltipSectionValidator)
});

export type AdvancedTooltip = Static<typeof AdvancedTooltipValidator>;
//#endregion

export const InactiveSectionValidator = Record({
  status: Literal("inactive"),
  title: String.nullable().optional(), // property used only on array items
  tooltip: AdvancedTooltipValidator.nullable().optional()
});

export type InactiveSection = Static<typeof InactiveSectionValidator>;

export const StatusValidator = Union(
  Literal("green"),
  Literal("yellow"),
  Literal("red"),
  Literal("gray")
);

export type Status = Static<typeof StatusValidator>;

export const PipelineActivityStatusValidator = Union(
  Literal("on track"),
  Literal("subject to change"),
  Literal("off track"),
  Literal("delayed"),
  Literal("accelerated")
);

export type PipelineActivityStatus = Static<
  typeof PipelineActivityStatusValidator
>;

export const StatusWithAcceleratedValidator = StatusValidator.Or(
  PipelineActivityStatusValidator
);

export type StatusWithAccelerated = Static<
  typeof StatusWithAcceleratedValidator
>;

export const PipelineEnrollmentStatusValidator = Union(
  Literal("green"),
  Literal("yellow"),
  Literal("red"),
  Literal("gray"),
  Literal("enrollment complete"),
  Literal("pending subject enrollment"),
  Literal("study fully enrolled")
);

export type PipelineEnrollmentStatus = Static<
  typeof PipelineEnrollmentStatusValidator
>;

export const OwnersValidator = Record({
  ceoStaffSponsors: Array(String),
  operatingTeamOwners: Array(String)
});

export type Owners = Static<typeof OwnersValidator>;

export const ExternalLinkValidator = Record({
  id: String.optional(), // not actually sent by BE, but used by FE to correctly track/map list items + errors
  label: String,
  href: String,
  isSensingExternal: Boolean.nullable().optional()
});

export type ExternalLink = Static<typeof ExternalLinkValidator>;

export const AgendaItemValidator = Record({
  agendaItemId: Number,
  name: String,
  archetype: ArchetypeValidator,
  value: Number,
  focus: Number,
  order: Number,
  status: StatusValidator.nullable()
});

export type AgendaItem = Static<typeof AgendaItemValidator>;

export const SummaryInfoValidator = Record({
  status: StatusValidator,
  reason: String.nullable().optional(),
  scope: String.nullable().optional(),
  keyInsights: String.nullable().optional()
});

export type SummaryInfo = Static<typeof SummaryInfoValidator>;

export const ChartItemValidator = Record({
  label: String.nullable(),
  value: Number.nullable(),
  forecast: Number.nullable()
});

export type ChartItem = Static<typeof ChartItemValidator>;

export const UserValidator = Record({
  fullName: String,
  username: String,
  email: String
});

export type User = Static<typeof UserValidator>;

export const SortInfoValidator = Record({
  property: String,
  direction: Union(Literal("asc"), Literal("desc"))
});

export type SortInfo = Static<typeof SortInfoValidator>;

export const PageInfoValidator = Record({
  pageSize: Number,
  pageNumber: Number,
  count: Number
});

export type PageInfo = Static<typeof PageInfoValidator>;
