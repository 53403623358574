import axios from "axios";
import { getKeyEventsId } from "utils/multitenancy";

import { Array, Number, Record, Static, String } from "runtypes";
import {
  OwnersValidator,
  LinkValidator,
  SummaryInfoValidator,
  ArchetypeValidatorV2
} from "./common-v2";
import {
  NextApprovalLaunchE2lValidator,
  NextPriorityMilestoneValidator,
  PipelineStudyEnrollmentValidator
} from "./pipeline/pipeline-project";

import {
  PipelineProducts,
  PipelineProductsValidator
} from "./pipeline/pipeline-product";

//TODO JCG: move types to separate file, leave only endpoints here

export const PipelineItemValidatorV2 = Record({
  id: Number,
  agendaItemVersionId: Number,
  archetype: ArchetypeValidatorV2,
  triggers: Array(String),
  summaryInfo: SummaryInfoValidator,
  title: String,
  owners: OwnersValidator,
  nextApprovalLaunchE2L: NextApprovalLaunchE2lValidator,
  nextPriorityMilestone: NextPriorityMilestoneValidator,
  studyEnrollments: Array(PipelineStudyEnrollmentValidator),
  links: Array(LinkValidator)
});

export type PipelineItemV2 = Static<typeof PipelineItemValidatorV2>;

export const getPipelineProducts = async (): Promise<PipelineProducts> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<PipelineProducts>(
    `${REACT_APP_DOMAIN}/api/v1/${getKeyEventsId()}/calendar_row_products`
  );

  PipelineProductsValidator.check(result.data);

  return result.data;
};
