import { Array, Number, Record, Static, String } from "runtypes";
import {
  SummaryInfoValidator,
  OwnersValidator,
  ExternalLinkValidator
} from "../common";
import {
  AgendaItemSectionEngagementScoreValidator,
  AgendaItemSectionInventoryBelowTargetValidator,
  AgendaItemSectionLocationValidator,
  AgendaItemSectionPipelineProjectValidator,
  AgendaItemSectionPlaceholderValidator,
  AgendaItemSectionSalesDemandValidator,
  AgendaItemSectionSingleMetricValidator,
  AgendaItemSectionTalentAcquisitionValidator,
  AgendaItemSectionTurnoverValidator
} from "./sections";

export const AgendaItemGroupValidator = Record({
  id: String, //uuid
  label: String,
  sections: Array(
    AgendaItemSectionPlaceholderValidator.Or(
      AgendaItemSectionSingleMetricValidator
    )
      .Or(AgendaItemSectionSalesDemandValidator)
      .Or(AgendaItemSectionPipelineProjectValidator)
      .Or(AgendaItemSectionLocationValidator)
      .Or(AgendaItemSectionInventoryBelowTargetValidator)
      .Or(AgendaItemSectionEngagementScoreValidator)
      .Or(AgendaItemSectionTalentAcquisitionValidator)
      .Or(AgendaItemSectionTurnoverValidator)
  )
});

export type AgendaItemGroup = Static<typeof AgendaItemGroupValidator>;

export const AgendaItemValidator = Record({
  agendaItemId: Number,
  name: String,
  summaryInfo: SummaryInfoValidator,
  owners: OwnersValidator,
  groups: Array(AgendaItemGroupValidator),
  links: Array(ExternalLinkValidator)
});

export type AgendaItem = Static<typeof AgendaItemValidator>;
