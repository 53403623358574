import { Array, Number, Record, Static, String } from "runtypes";
import { AdEntityValidator } from "api/common/ad-entity";

export const AgendaValidator = Record({
  id: Number,
  title: String,
  publishedDate: String,
  owner: AdEntityValidator,
  admins: Array(AdEntityValidator),
  fullViewers: Array(AdEntityValidator),
  limitedViewers: Array(AdEntityValidator)
});

export type Agenda = Static<typeof AgendaValidator>;
