import React, { useContext, useEffect, useState } from "react";
import "./TenantSelector.scss";
import { getAgendaId, getKeyEventsId } from "utils/multitenancy";
import Dropdown, {
  MenuItemType,
  ItemType
} from "common-components/dropdown/Dropdown";
import { useNavigate } from "react-router-dom";
import pages from "pages/pages";
import { AgendaList, CalendarList, getAgendaList, getCalendarList } from "api";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "global/query-config";
import { PermissionsContext } from "global/permissions";

const namespace = "rts-pa-tenant-selector";
const ADD_KEY = "add";

const initItem: MenuItemType = {
  key: "0",
  label: "Loading..."
};

type ListMode = {
  type: "agenda" | "calendar" | "error";
  items: ItemType[];
  selectedItemId: string;
  getUrlId: () => string;
  itemGo: (id: string) => string;
  addRouteGo: () => string;
};

const initListMode: ListMode = {
  type: "error",
  items: [],
  selectedItemId: "-1",
  getUrlId: () => "-1",
  itemGo: () => "-1",
  addRouteGo: () => ""
};

const TenantSelector = () => {
  const navigate = useNavigate();
  const agendaId = getAgendaId();
  const keyEventsId = getKeyEventsId();
  const perms = useContext(PermissionsContext);

  const [selectedItem, setSelectedItem] = useState<MenuItemType>(initItem);
  const [listMode, setListMode] = useState<ListMode>(initListMode);

  const { data: agendaList } = useQuery<AgendaList>(
    ["agenda-list"],
    getAgendaList
  );

  const { data: calendarList } = useQuery<CalendarList>(
    ["calendar-list"],
    getCalendarList
  );

  const onItemChange = (listMode: ListMode, itemId: string) => {
    const item = listMode.items.find(a => a?.key === itemId);

    if (item) {
      setSelectedItem(item as MenuItemType);
    } // else {
    //   // on item delete, it refreshes list which triggers this reroute (other reroute in permissions)
    //   const newItemId = listMode.items[0]?.key?.toString();

    //   if (newItemId) {
    //     navigate(listMode.itemGo(newItemId));
    //     setSelectedItem(initItem);
    //   } else {
    //     // handle no longer having access to current page
    //     let otherId: number | undefined;
    //     let otherRouteGo: (id: string) => string = () => "";
    //     if (listMode.type === "agenda") {
    //       otherId = calendarList?.calendars[0].calendarId;
    //       otherRouteGo = (id: string) => pages.keyEvents.go(id); // need to wrap due to `this` usage in pages
    //     } else if (listMode.type === "calendar") {
    //       otherId = agendaList?.agendas[0].agendaId;
    //       otherRouteGo = (id: string) => pages.grid.go(id); // need to wrap due to `this` usage in pages
    //     }

    //     if (otherId) {
    //       navigate(otherRouteGo(otherId.toString()));
    //     } else {
    //       navigate(pages.unauthorized.go());
    //     }
    //   }
    // }

    // invalidate/refetch everything that's needed on item change; skip initially, and on item config update
    if (selectedItem.key !== "0" && selectedItem.key !== item?.key) {
      queryClient.removeQueries({
        predicate: query =>
          ![
            "agenda", // keep agendas cached for quick change b/w them, but clear out specific item modals
            "calendar", // keep calendars cached for quick change b/w them, but clear out specific item modals
            "agenda-list",
            "calendar-list",
            "global-config"
          ].includes(query.queryKey[0] as string)
      });
    }
  };

  // sets list mode, and adds items
  useEffect(() => {
    let listMode: ListMode;

    if (agendaId !== "0") {
      listMode = {
        type: "agenda",
        items: [],
        selectedItemId: agendaId,
        getUrlId: getAgendaId,
        itemGo: (id: string) => pages.grid.go(id), // need to wrap due to `this` usage in pages
        addRouteGo: () => pages.grid.addAgendaModal.go() // need to wrap due to `this` usage in pages
      };

      if (!agendaList) {
        return;
      }

      listMode.items = agendaList.agendas.map<ItemType>(ali => ({
        key: ali.agendaId.toString(),
        label: `${ali.title} Prioritized Agenda`
      }));

      if (perms.grid.create) {
        listMode.items = listMode.items.concat([
          { type: "divider" },
          {
            key: ADD_KEY,
            label: "Create a new agenda +",
            style: { fontWeight: 700 }
          }
        ]);
      }
    } else if (keyEventsId !== "0") {
      listMode = {
        type: "calendar",
        items: [],
        selectedItemId: keyEventsId,
        getUrlId: getKeyEventsId,
        itemGo: (id: string) => pages.keyEvents.go(id), // need to wrap due to `this` usage in pages
        addRouteGo: () => pages.keyEvents.addCalendarModal.go() // need to wrap due to `this` usage in pages
      };

      if (!calendarList) {
        return;
      }

      listMode.items = calendarList.calendars.map<ItemType>(cli => ({
        key: cli.calendarId.toString(),
        label: `${cli.title} Forward 12 Quarters`
      }));

      if (perms.keyEvents.create) {
        listMode.items = listMode.items.concat([
          { type: "divider" },
          {
            key: ADD_KEY,
            label: "Create a new calendar +",
            style: { fontWeight: 700 }
          }
        ]);
      }
    } else {
      // should never happen
      listMode = initListMode;
    }

    setListMode(listMode);
    onItemChange(listMode, listMode.getUrlId());
  }, [agendaId, keyEventsId, agendaList, calendarList]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dropdown
      className={namespace}
      items={listMode.items}
      onMenuItemClick={info => {
        if (info.key === ADD_KEY) {
          navigate(listMode.addRouteGo());
        } else {
          navigate(listMode.itemGo(info.key));
          onItemChange(listMode, info.key);
        }
      }}
      selectable={true}
      defaultSelectedKey={listMode.selectedItemId}
    >
      {selectedItem.label}
    </Dropdown>
  );
};

export default TenantSelector;
