import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  getAgendaItemsV2,
  getAgendaItemV2,
  postAgendaItemV2,
  putAgendaItemV2,
  deleteAgendaItemV2 as deleteAgendaItemV2ApiCall,
  AgendaItemWithDetailsV2
} from "api";
import PaModal from "common-components/pa-modal/PaModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import "./UpsertAgendaItemModal.scss";
import { LinksInput, Input, StatusSelector, TextArea } from "common-components";
import { Button } from "@opsdti-global-component-library/amgen-design-system";
import { useValidator } from "global/use-validator";
import produce from "immer";
import { useStatusManager } from "global/use-status-manager";
import TrashIcon from "icons/trash-icon";
import { useNavigate, useSearchParams } from "react-router-dom";
import pages from "pages/pages";
import { PermissionsContext } from "global/permissions";
import InputList from "common-components/input-list/InputList";
import { queryClient } from "global/query-config";
import { getAgendaId } from "utils/multitenancy";
import { useModalHelper } from "global/use-modal-helper";
import { Link, Owner } from "api/common-v2";

const initialItem: AgendaItemWithDetailsV2 = {
  id: 0,
  agendaItemVersionId: 0,
  archetype: "standalone",
  archetypeLinkId: "",
  name: "",
  focus: 1,
  value: 1,
  order: 99,
  statusType: "manual",
  summaryInfo: {
    status: "green",
    reason: "",
    statusDrivers: [],
    keyInsights: "",
    scope: ""
  },
  owners: {
    ceoStaffSponsors: [],
    operatingTeamOwners: []
  },
  links: []
};

type AddMode = {
  text: "Add";
  isAdd: true;
  isEdit: false;
  params: {
    focus: number;
    value: number;
  };
};

type EditMode = {
  text: "Edit";
  isAdd: false;
  isEdit: true;
};

type ModalMode = AddMode | EditMode;

const namespace = "rts-pa-upsert-agenda-item-modal-v2";

const UpsertAgendaItemModal = () => {
  const agendaId = getAgendaId();
  const hasPermissions = useContext(PermissionsContext).grid.edit;
  const navigate = useNavigate();
  const mhResult = useModalHelper(
    [pages.grid.edit.addItemModal.path, pages.grid.edit.editItemModal.path],
    hasPermissions
  );
  const [agendaItem, setAgendaItem] =
    useState<AgendaItemWithDetailsV2>(initialItem);

  const [searchParams] = useSearchParams();

  const mode: ModalMode = useMemo(() => {
    if (mhResult.path === pages.grid.edit.addItemModal.path) {
      let focus = parseInt(searchParams.get("focus") || "");
      let value = parseInt(searchParams.get("value") || "");

      if (!(1 <= focus && focus <= 3)) {
        focus = 1;
      }

      if (!(1 <= value && value <= 3)) {
        value = 1;
      }

      return {
        text: "Add",
        isAdd: true,
        isEdit: false,
        params: {
          focus: focus,
          value: value
        }
      };
    } else {
      return {
        text: "Edit",
        isAdd: false,
        isEdit: true
      };
    }
  }, [mhResult, searchParams]);

  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const { containerUtils, fieldUtils } = useValidator();

  const { data: items, refetch: refreshGrid } = useQuery(
    ["agenda-items", false],
    () => getAgendaItemsV2(false)
  );

  const {
    data: apiAgendaItem,
    status: apiAgendaItemStatus,
    remove: refreshApiAgendaItem,
    error
  } = useQuery(
    ["agenda-item", mhResult.itemId],
    () => getAgendaItemV2(mhResult.itemId),
    {
      enabled: mhResult.enabled && mode.isEdit
    }
  );

  const { status: addStatus, mutate: addAgendaItem } = useMutation(
    postAgendaItemV2,
    {
      onMutate: () => {
        overrideStatus("addAgendaItem", "loading");
      },
      onSuccess: () => {
        refreshGrid().then(() => {
          navigate(pages.grid.edit.go());
          setTimeout(() => {
            //keep spinner going when closing modal
            overrideStatus("addAgendaItem", undefined);
          }, 500);
        });
      },
      onError: () => {
        overrideStatus("addAgendaItem", undefined);
      }
    }
  );
  const { status: updateStatus, mutate: updateAgendaItem } = useMutation(
    putAgendaItemV2,
    {
      onMutate: () => {
        overrideStatus("putAgendaItem", "loading");
      },
      onSuccess: () => {
        refreshGrid().then(() => {
          navigate(pages.grid.edit.go());

          refreshApiAgendaItem(); //standalone, and all
          queryClient.invalidateQueries({
            queryKey: ["agenda-item-cached", mhResult.itemId]
          });

          setTimeout(() => {
            //keep spinner going when closing modal
            overrideStatus("putAgendaItem", undefined);
          }, 500);
        });
      },
      onError: () => {
        overrideStatus("putAgendaItem", undefined);
      }
    }
  );
  const { status: deleteStatus, mutate: deleteAgendaItem } = useMutation(
    deleteAgendaItemV2ApiCall,
    {
      onMutate: () => {
        overrideStatus("deleteAgendaItem", "loading");
      },
      onSuccess: () => {
        refreshGrid().then(() => {
          navigate(pages.grid.edit.go());
          setTimeout(() => {
            //keep spinner going when closing modal
            overrideStatus("deleteAgendaItem", undefined);
            setIsDeleteConfirmation(false);
          }, 500);
        });
      },
      onError: () => {
        overrideStatus("deleteAgendaItem", undefined);
      }
    }
  );

  const { status, overrideStatus } = useStatusManager(
    mode.isEdit && mhResult.enabled ? apiAgendaItemStatus : "idle",
    addStatus,
    updateStatus,
    deleteStatus
  );

  //initialize modal on open/close
  useEffect(() => {
    if (!mhResult.enabled) {
      setTimeout(() => {
        setAgendaItem(initialItem);
        containerUtils.resetValidation();
      }, 500);
      return;
    }

    if (mode.isAdd) {
      const newItem = produce(initialItem, draftState => {
        if (!mode.isAdd) {
          return; //never happens, satisfies typescript
        }

        const focus = mode.params.focus;
        const value = mode.params.value;

        //find+set last "order" value for [focus, value] box
        const lowGroup = items?.groups.find(
          g => g.focus === focus && g.value === value
        );

        if (lowGroup) {
          let lastOrder = 1;

          for (let index = 0; index < lowGroup.items.length; index++) {
            const element = lowGroup.items[index];

            if (element.order >= lastOrder) {
              lastOrder = element.order + 1;
            }
          }

          draftState.order = lastOrder;
          draftState.focus = focus;
          draftState.value = value;
        }
      });

      setAgendaItem(newItem);
    }
  }, [mhResult.enabled]); // eslint-disable-line react-hooks/exhaustive-deps

  //initialize agendaItem when retrieved from DB (edit mode)
  useEffect(() => {
    if (!apiAgendaItem) {
      return;
    }

    const tempApiAgendaItem = produce(apiAgendaItem, draftState => {
      /* TODO JCG: once UpsertAgendaItemModal is deleted, update InputList
         to use .text instead of .value and remove this logic
       */
      const fixOwners = (list: Owner[]) => {
        for (const item of list) {
          (item as any).value = item.text; // eslint-disable-line @typescript-eslint/no-explicit-any
        }
      };

      fixOwners(draftState.owners.ceoStaffSponsors);
      fixOwners(draftState.owners.operatingTeamOwners);
    });

    setAgendaItem(tempApiAgendaItem);
  }, [apiAgendaItem]);

  //update status on statusType change
  useEffect(() => {
    if (agendaItem.statusType === "automatic") {
      setAgendaItem(
        produce(agendaItem, draftState => {
          draftState.summaryInfo.status =
            apiAgendaItem?.summaryInfo.status || "gray";
          draftState.summaryInfo.reason =
            apiAgendaItem?.summaryInfo.reason || "";
        })
      );
    } else if (agendaItem.statusType === "manual") {
      setAgendaItem(
        produce(agendaItem, draftState => {
          draftState.summaryInfo.status = "green";
        })
      );
    }
  }, [agendaItem.statusType]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = () => {
    containerUtils.triggerValidation().then(result => {
      if (!result.isValid) {
        return;
      }

      const savedItem = produce(agendaItem, draftState => {
        const convertIds = (list: Link[] | Owner[]) => {
          //convert from GUID to 0 for list items
          for (const item of list) {
            if (typeof item.id === "string") {
              item.id = 0;
            }
          }
        };

        /* TODO JCG: once UpsertAgendaItemModal is deleted, update InputList
           to use .text instead of .value and remove this logic
         */
        const fixOwners = (list: Owner[]) => {
          for (const item of list) {
            item.text = (item as any).value; // eslint-disable-line @typescript-eslint/no-explicit-any
          }
        };

        convertIds(draftState.links);
        convertIds(draftState.owners.ceoStaffSponsors);
        fixOwners(draftState.owners.ceoStaffSponsors);
        convertIds(draftState.owners.operatingTeamOwners);
        fixOwners(draftState.owners.operatingTeamOwners);
      });

      if (mode.isAdd) {
        addAgendaItem(savedItem);
      } else if (mode.isEdit) {
        updateAgendaItem(savedItem);
      }
    });
  };

  const isSaving =
    addStatus === "loading" ||
    updateStatus === "loading" ||
    deleteStatus === "loading";

  const onClose = () => {
    if (isSaving) {
      return;
    }

    setTimeout(() => {
      setIsDeleteConfirmation(false);
    }, 500);

    navigate(pages.grid.edit.go());
  };

  let footerButtons: JSX.Element;
  const footerClassName = `${namespace}-footer`;
  if (isDeleteConfirmation) {
    footerButtons = (
      <div className={footerClassName}>
        <Button
          text="Cancel Delete"
          type="secondary"
          onClick={() => {
            setIsDeleteConfirmation(false);
          }}
          className={`${namespace}-delete-button`}
          disabled={deleteStatus === "loading"}
          danger={true}
        />
        <Button
          text="Confirm Delete"
          type="primary"
          onClick={() => {
            deleteAgendaItem(agendaItem.id);
          }}
          className={`${namespace}-delete-button`}
          disabled={deleteStatus === "loading"}
          danger={true}
        />
      </div>
    );
  } else if (mode.isEdit) {
    footerButtons = (
      <div className={footerClassName}>
        <Button
          text="Delete Item"
          type="primary"
          onClick={() => {
            setIsDeleteConfirmation(true);
          }}
          icon={<TrashIcon />}
          className={`${namespace}-delete-button`}
          disabled={isSaving}
          danger={true}
        />
        <Button
          text="Save"
          type="primary"
          onClick={onSave}
          disabled={isSaving}
        />
      </div>
    );
  } else {
    //mode.isAdd
    footerButtons = (
      <div className={footerClassName}>
        <Button
          text="Cancel"
          type="secondary"
          onClick={() => {
            navigate(pages.grid.edit.go());
          }}
          disabled={isSaving}
        />
        <Button
          text="Save"
          type="primary"
          onClick={onSave}
          disabled={isSaving}
        />
      </div>
    );
  }

  return (
    <PaModal
      title={`Prioritized Agenda Item: ${mode.text}`}
      status={status}
      axiosErrors={error}
      isOpen={mhResult.enabled}
      onClose={onClose}
      className={namespace}
      footer={footerButtons}
    >
      <div className={`${namespace}-content`}>
        <Input
          label="Item Name"
          placeholder="Name"
          required
          autoUpdater={{
            item: agendaItem,
            setItem: setAgendaItem,
            propExpression: x => x.name
          }}
          validator={fieldUtils}
        />
        {agendaItem.statusType === "manual" && (
          <>
            <StatusSelector
              required
              autoUpdater={{
                item: agendaItem,
                setItem: setAgendaItem,
                propExpression: x => x.summaryInfo.status
              }}
            />
            <TextArea
              label="Status Reason"
              placeholder="Text"
              autoUpdater={{
                item: agendaItem,
                setItem: setAgendaItem,
                propExpression: x => x.summaryInfo.reason
              }}
            />
          </>
        )}
        <TextArea
          label="Scope"
          placeholder="Text"
          autoUpdater={{
            item: agendaItem,
            setItem: setAgendaItem,
            propExpression: x => x.summaryInfo.scope
          }}
        />
        <TextArea
          label="Key Insights"
          placeholder="Text"
          autoUpdater={{
            item: agendaItem,
            setItem: setAgendaItem,
            propExpression: x => x.summaryInfo.keyInsights
          }}
        />
        <div className={`${namespace}-content-owners`}>
          <InputList
            title={agendaId === "1" ? "CEO Staff Sponsor" : "Sponsor"}
            autoUpdater={{
              item: agendaItem,
              setItem: setAgendaItem,
              propExpression: x => x.owners.ceoStaffSponsors
            }}
            validator={fieldUtils}
          />
          <InputList
            title={agendaId === "1" ? "Operating Team Owner" : "Owner"}
            autoUpdater={{
              item: agendaItem,
              setItem: setAgendaItem,
              propExpression: x => x.owners.operatingTeamOwners
            }}
            validator={fieldUtils}
          />
        </div>
        <LinksInput
          title="External Links"
          autoUpdater={{
            item: agendaItem,
            setItem: setAgendaItem,
            propExpression: x => x.links
          }}
          validator={fieldUtils}
        />
      </div>
    </PaModal>
  );
};

export default UpsertAgendaItemModal;
